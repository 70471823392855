/**
 * @generated SignedSource<<79f7bf00c16dfbaf9dc681c10892bcf2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AcceptOfferModalContent_criteriaAsset$data = {
  readonly __typename: "AssetType";
  readonly assetContract: {
    readonly address: string;
  };
  readonly chain: {
    readonly identifier: ChainIdentifier;
  };
  readonly defaultRarityData: {
    readonly rank: number;
  } | null;
  readonly isCurrentlyFungible: boolean;
  readonly ownedQuantity: string | null;
  readonly relayId: string;
  readonly tokenId: string;
  readonly " $fragmentSpreads": FragmentRefs<"FloorPriceDifference_item" | "ItemOfferDetails_item" | "readOptionalCreatorFees_item">;
  readonly " $fragmentType": "AcceptOfferModalContent_criteriaAsset";
};
export type AcceptOfferModalContent_criteriaAsset$key = {
  readonly " $data"?: AcceptOfferModalContent_criteriaAsset$data;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferModalContent_criteriaAsset">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "identity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AcceptOfferModalContent_criteriaAsset",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetContractType",
      "kind": "LinkedField",
      "name": "assetContract",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChainType",
      "kind": "LinkedField",
      "name": "chain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "identifier",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tokenId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "identity",
          "variableName": "identity"
        }
      ],
      "kind": "ScalarField",
      "name": "ownedQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCurrentlyFungible",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RarityDataType",
      "kind": "LinkedField",
      "name": "defaultRarityData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rank",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemOfferDetails_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FloorPriceDifference_item"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readOptionalCreatorFees_item",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CollectionType",
                  "kind": "LinkedField",
                  "name": "collection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isCreatorFeesEnforced",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCreatorFeeBasisPoints",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "AssetType",
              "abstractKey": null
            }
          ],
          "type": "ItemType",
          "abstractKey": "__isItemType"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};
})();

(node as any).hash = "cd11ebe57156b13f3c8432556fc55cc3";

export default node;
