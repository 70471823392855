/**
 * @generated SignedSource<<64ce447ea09d7c304795ff243c60dc8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
export type OrderV2Side = "ASK" | "BID" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AcceptOfferButton_order$data = {
  readonly item: {
    readonly __typename: "AssetBundleType";
    readonly assetQuantities: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly asset: {
            readonly ownedQuantity: string | null;
          };
        } | null;
      } | null>;
    };
    readonly bundleCollection: {
      readonly statsV2: {
        readonly floorPrice: {
          readonly eth: string;
        } | null;
      };
    } | null;
    readonly chain: {
      readonly identifier: ChainIdentifier;
    };
    readonly " $fragmentSpreads": FragmentRefs<"itemEvents_dataV2">;
  } | {
    readonly __typename: "AssetType";
    readonly acceptOfferDisabled: {
      readonly __typename: "AcceptOfferDisabledType";
    } | null;
    readonly chain: {
      readonly identifier: ChainIdentifier;
    };
    readonly collection: {
      readonly statsV2: {
        readonly floorPrice: {
          readonly eth: string;
        } | null;
      };
    };
    readonly ownedQuantity?: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"itemEvents_dataV2">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly maker: {
    readonly address: string;
  };
  readonly orderType: OrderV2OrderType;
  readonly perUnitPriceType: {
    readonly eth: string;
  };
  readonly relayId: string;
  readonly side: OrderV2Side | null;
  readonly " $fragmentType": "AcceptOfferButton_order";
};
export type AcceptOfferButton_order$key = {
  readonly " $data"?: AcceptOfferButton_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferButton_order">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "eth",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CollectionStatsV2Type",
    "kind": "LinkedField",
    "name": "statsV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PriceType",
        "kind": "LinkedField",
        "name": "floorPrice",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "identity",
        "variableName": "identity"
      }
    ],
    "kind": "ScalarField",
    "name": "ownedQuantity",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  }
],
v7 = {
  "kind": "InlineDataFragmentSpread",
  "name": "itemEvents_dataV2",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tokenId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetContractType",
              "kind": "LinkedField",
              "name": "assetContract",
              "plural": false,
              "selections": (v6/*: any*/),
              "storageKey": null
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        }
      ],
      "type": "ItemType",
      "abstractKey": "__isItemType"
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "skipOwnedQuantity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AcceptOfferButton_order",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "side",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AcceptOfferDisabledType",
              "kind": "LinkedField",
              "name": "acceptOfferDisabled",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CollectionType",
              "kind": "LinkedField",
              "name": "collection",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "condition": "skipOwnedQuantity",
              "kind": "Condition",
              "passingValue": false,
              "selections": (v5/*: any*/)
            },
            (v7/*: any*/)
          ],
          "type": "AssetType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "bundleCollection",
              "args": null,
              "concreteType": "CollectionType",
              "kind": "LinkedField",
              "name": "collection",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 30
                }
              ],
              "concreteType": "AssetQuantityTypeConnection",
              "kind": "LinkedField",
              "name": "assetQuantities",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetQuantityTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetQuantityType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AssetType",
                          "kind": "LinkedField",
                          "name": "asset",
                          "plural": false,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "assetQuantities(first:30)"
            },
            (v7/*: any*/)
          ],
          "type": "AssetBundleType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "maker",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "perUnitPriceType",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "292e1bdae0b43d8bd31c5a695210a30d";

export default node;
